<script>
    import Field from "./Field.svelte";
    import {createEventDispatcher} from "svelte";

    const dispatch = createEventDispatcher();

     let layer = {
        layer_type: "container",
        name: ""
     }

     let selectField = {
         type: "select",
         label: "Layer Type",
         name: "layer_type",
         choices: {static: [
            {label: "Container", value: "container"},
            {label: "Token", value: "token"},
            {label: "Image", value: "image"},
            {label: "Fog", value: "fog"},
            {label: "Dynamic Lighting", value: "dynamic_lighting"}
         ]}
     }

     function onSubmit(data) {
         dispatch("submit", data);
     };

     function cancelAction() {
         dispatch("cancel")
     };

</script>

<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
    <form on:submit|preventDefault={() => onSubmit(layer)}>
        <header class="modal-card-head">
            <p class="modal-card-title">Add Layer</p>
            <span class="delete" aria-label="close" on:click|preventDefault={cancelAction}></span>
        </header>
        <section class="modal-card-body">
            <Field {...selectField} bind:value={layer.layer_type} name="layer_type" />
            <Field label="Layer Name" name="layer_name" bind:value={layer.name} required={true} />
            {#if layer.layer_type == "image" || layer.layer_type == "token"}
                <Field label="Image URL" name="layer_image" bind:value={layer.url} required={true}/>
                <Field label="Layer Link" name="layer_link" bind:value={layer.link} />
            {/if}
        </section>
        <footer class="modal-card-foot">
            <slot name="buttons">
                <div class="button" on:click|preventDefault={cancelAction}>Cancel</div>
                <button class="button is-success">Save changes</button>
            </slot>
        </footer>
    </form>
    </div>
</div>

<style>
    .modal-card-foot {
        justify-content: flex-end;
    }</style>