<script>
    import System from '../../lib/models/SystemModel';
    import {addFormModal} from '../../_components/ModalFormManager.svelte';
    import {isActive, url} from '@roxi/routify'

    let system_list = System.getSystemList();

    function addSystem() {
        let fields = [
            {
                name: "id",
                label: "ID",
                placeholder: "Unique System ID",
                required: true
            },
            {
                name: "name",
                label: "Name",
                placeholder: "System Name",
                required: true
            }
        ]

        addFormModal({
            fields: fields,
            title: "Add System"
        }).then((data) => {
            System.addSystem(data);
        }).catch(e => {
            console.log(e);
        })
    }

</script>
<div class="columns">
    <div class="column is-2 system-column">
        <nav class="panel system-panel">
            <p class="panel-heading">Systems</p>

            {#each $system_list as system (system._id)}
            <a class="panel-block" class:active={$isActive(`./${system.id}`)} href="{$url(`./${system.id}`)}">
                    {system.name}
                </a>
            {/each}

            <div class="panel-block bottom">
                <button class="button is-link is-outlined is-fullwidth" on:click={addSystem}>
                    Add New System
                </button>
            </div>
        </nav>

</div>
<slot />
</div>

<style>
    .columns {
        height: calc(100vh - 62px);
        margin-top: 5px;
    }
    .system-panel {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .system-column {
        height: 100%;
    }</style>