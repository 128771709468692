<script>
  import { Router } from "@roxi/routify";
  import { routes } from "../.routify/routes";
  import PopupManager from './_components/PopupManager.svelte';
  import ContextMenu from './_components/ContextMenu.svelte';
  import ModalFormManager from './_components/ModalFormManager.svelte';
  import Notifications from "./_components/svelte-notify/src/notification/Notifications.svelte";
  import LinkManager from './_components/LinkManager.svelte';
  import UndoManager from './_components/UndoManager.svelte';

  // useless import? may be needed to
  // keep the session object from being GCed idk
  import session from './lib/Session';
</script>

<style  global>:global(.grow) {
	flex-grow: 1;
}

:global(.pointer) {
	cursor: pointer;
}

:global(.flex-column), :global(.panel) {
	display: flex;
	flex-direction: column;
}

:global(.panel-block.bottom) {
	margin-top: auto;
}

:global(.panel-block.active) {
	background-color:  hsl(204, 86%, 53%);
	color: white;
}

:global(.panel-tabs) :global(span) {
	border-bottom: 1px solid #dbdbdb;
	margin-bottom: -1px;
	padding: .5em;
}

:global(.panel-tabs) :global(.is-active) {
	border-bottom-color: #4a4a4a;
	color: #363636;
}

:global(.panel-block.active:hover) {
	background-color: hsl(206, 70%, 96%);
	color: #3298dc !important;
}

:global(span.link) {
	color: #3273dc;
	cursor: pointer;
	text-decoration: none;
}

:global(html) {
	overflow-y: auto;
}

:global(.mouseoverlay) {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 9999;
	cursor: move;
}

:global(#loader) {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	height: 50px;
	width: 50px;
	margin: -25px 0 0 -25px;
}

:global(#loader) :global(span) {
	position: absolute;
	display: block;
	bottom: 10px;
	width: 9px;
	height: 5px;
	background: rgba(0, 0, 0, 0.25);
	-webkit-animation: loader 1.5s  infinite ease-in-out;
			animation: loader 1.5s  infinite ease-in-out;
}

:global(#loader) :global(span:nth-child(2)) {
	left: 11px;
	-webkit-animation-delay: 0.2s;
			animation-delay: 0.2s;
}

:global(#loader) :global(span:nth-child(3)) {
	left: 22px;
	-webkit-animation-delay: 0.4s;
			animation-delay: 0.4s;
}

:global(#loader) :global(span:nth-child(4)) {
	left: 33px;
	-webkit-animation-delay: 0.6s;
			animation-delay: 0.6s;
}

:global(#loader) :global(span:nth-child(5)) {
	left: 44px;
	-webkit-animation-delay: 0.8s;
			animation-delay: 0.8s;
}

@keyframes -global-loader {
	0% {
		height: 5px;
		-webkit-transform: translateY(0px);
				transform: translateY(0px);
		-webkit-transform: translateY(0px);
				transform: translateY(0px);
		background: rgba(0, 0, 0, 0.25);
	}
	25% {
		height: 30px;
		-webkit-transform: translateY(15px);
				transform: translateY(15px);
		-webkit-transform: translateY(15px);
				transform: translateY(15px);
		background: #0057e7;
	}
	50% {
		height: 5px;
		-webkit-transform: translateY(0px);
				transform: translateY(0px);
		-webkit-transform: translateY(0px);
				transform: translateY(0px);
		background: rgba(0, 0, 0, 0.25);
	}
	100% {
		height: 5px;
		-webkit-transform: translateY(0px);
				transform: translateY(0px);
		-webkit-transform: translateY(0px);
				transform: translateY(0px);
		background: rgba(0, 0, 0, 0.25);
	}
}

@-webkit-keyframes -global-loader {
	0% {
		height: 5px;
		-webkit-transform: translateY(0px);
				transform: translateY(0px);
		background: rgba(0, 0, 0, 0.25);
	}
	25% {
		height: 30px;
		-webkit-transform: translateY(15px);
				transform: translateY(15px);
		background: #0057e7;
	}
	50% {
		height: 5px;
		-webkit-transform: translateY(0px);
				transform: translateY(0px);
		background: rgba(0, 0, 0, 0.25);
	}
	100% {
		height: 5px;
		-webkit-transform: translateY(0px);
				transform: translateY(0px);
		background: rgba(0, 0, 0, 0.25);
	}
}

:global(.notifications) {
    position: fixed !important;
    z-index: 100;
  }

:global(.chat) {
    position: fixed;
    bottom: 0;
    right: 100px;
  }</style>

<Router {routes} />

<ContextMenu />
<Notifications  position="bottom-right" />
<LinkManager />

<!--
  <Popup title="Chat" closable={false}>
    <ChatBox></ChatBox>
  </Popup>
-->

<PopupManager />
<ModalFormManager />
<UndoManager />