<script context="module">
    import {writable} from "svelte/store";

    let selected = writable("");

    let maxZ = 999;
    let startX = 200;
    let startY = 300;
</script>

<script>
    import {draggable} from '../lib/draggable.svelte'
	import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher();

    import {onMount} from 'svelte';

    export let component = null;
    export let data = null;
    export let title = "";
    export let id = null;
    export let width = 800;
    export let closable = true;
    export let full = false;

    export let x = startX;
    export let y = startY;

    let collapsed;

    startX += 50;
    startY += 50;

    let zIndex = maxZ;
    maxZ++;


    function select() {
        $selected = id;
        if (zIndex < maxZ) {
            zIndex = ++maxZ;
        }
    }

    onMount(() => {
        if (startX > window.innerWidth - 800) {
            startX = 100;
        }

        if (startY > window.innerHeight - 600) {
            startY = 100
        }
    })

    $: widthpx = width == "auto" ? "auto" : width +"px";
</script>

<style>
    .popup {
        position: fixed;
        z-index: 100;
    }
    .card-content {
        overflow: auto;
        height: 600px;
        resize: both;
        padding: 0;
    }
    .card-header {
        cursor: move;
        user-select: none;
    }
    .selected {
        -z-index: 999;
    }
    .collapse {
        cursor: pointer;
    }
    .hidden{
        display: none;
    }
    .full {
        padding: 0;
        overflow: hidden;
    }</style>

<div
    class="popup card"
    class:selected={$selected==id}
    on:mousedown={select}
    style="top: {y}px; left: {x}px; z-index:{zIndex}"
    use:draggable>
    <header class="card-header handle">
        <p class="card-header-title is-capitalized">
            <span class="icon collapse" on:mousedown|preventDefault|stopPropagation={() => collapsed = !collapsed}>
                {@html collapsed ? "&#11167;" : "&#10148;"}
            </span>
            {title}
        </p>
        {#if closable}
        <span class="card-header-icon">
            <i class="icon" on:mousedown={() => dispatch("close")}>
                <i class="delete"></i>
            </i>
        </span>
        {/if}
    </header>
    <div class="card-content" style="width: {widthpx}" class:hidden={collapsed} class:full>
        {#if component}
          <svelte:component this={component} {...data} bind:title={title} />
        {:else}
            <slot />
        {/if}
    </div>
</div>