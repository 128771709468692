<script>
    import { Liquid } from 'liquidjs';
    import marked from 'marked';
    import DOMPurify from 'dompurify';

    export let title = '';

    const engine = new Liquid();

    const renderer = {
        paragraph: (txt) => `<p class="markdown">${txt}</p>`
    }
    marked.use({renderer})

    engine.registerFilter("md", (txt) => txt ? marked(txt) : "");
    engine.registerFilter("modifier", formatModifier )
    engine.registerFilter("ordinal", ordinal_suffix_of)
    engine.registerFilter("link", (text, id) => `<span class="link" data-linkID="${id}">${text}</span>`)

    engine.registerFilter("rollMod", (num, actor, stat) => `<span class="link" data-roll="1d20${formatModifier(num)}" data-roll-actor="${actor || ''}" data-roll-stat="${stat || ''}">${formatModifier(num)}</span>`)

    engine.registerFilter("rollDmg", (dice, actor, dmgType) => `<span class="link" data-roll="${dice}" data-roll-actor="${actor || ''}" data-roll-stat="${dmgType} Damage">${dice} ${dmgType}</span>`)

    // https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    function formatModifier (num) {
        return num >= 0 ? `+${num}` : num;
    }

    export let template;
    export let data;

    let renderFrame;
    export let error_message = "";

    function render(template, data) {

        if (!(template && data)) {
            error_message = ""
            return;
        }

        try {
            engine.parseAndRender(template.template, data).then((result) => {
                // we break the style tag so svelte doesn't get confused
                let html = DOMPurify.sanitize('<st' + `yle>${template.css}</style>${result}`, {FORCE_BODY: true});
                renderFrame.contentWindow.document.body.innerHTML = html;

                // search for title element
                let title_elem = renderFrame.contentWindow.document.querySelector("[data-title]");

                if (title_elem) {
                    title = title_elem.dataset.title;
                    console.log(title);
                }
            }).catch(e => {
                error_message = `Invalid Template ${e}`
            });
        } catch {
            error_message = "Invalid Template"
            return;
        }

        error_message = "";
    }

    $: render(template, data);
</script>

<iframe src="/popup.html" title="render" bind:this={renderFrame} on:load={() => render(template, data)}>
</iframe>

<style>
    iframe {
        width: 100%;
        height: 100%;
    }</style>