<script>
    import Field from './Field.svelte';
    import {onMount} from 'svelte';

    export let id;

    export let fields = [];
    export let data = {};
    export let errors = {};

    export let showDelete = false;

    export let title = "";

    export let cancelAction = () => {};
    export let saveAction = () => {};
    export let deleteAction = () => {};

    export let validate = () => { return {success: true}};

    let formElem;

    function onSubmit(data) {
        if (validate) {
            let validator = validate(data);

            if (validator.success) {
                saveAction(data);
            } else {
                alert(validator.message);
            }
        } else {
            saveAction(data);
        }
    }

    onMount(() => {
        formElem.querySelector("input").focus();
    })
</script>

<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
    <form bind:this={formElem} on:submit|preventDefault={() => onSubmit(data)}>
        <header class="modal-card-head">
            <p class="modal-card-title">{title}</p>
            <span class="delete" aria-label="close" on:click|preventDefault={cancelAction}></span>
        </header>
        <section class="modal-card-body">
            {#each fields as field}
                {#if !(field.type == "hidden")}
                    <Field {...field} bind:value={data[field.name]} {errors} />
                {/if}
            {/each}
        </section>
        <footer class="modal-card-foot">
            <slot name="buttons">
                {#if showDelete}
                    <button class="button is-danger is-left" on:click|preventDefault={deleteAction}>Delete</button>
                {/if}
                <span class="button" on:click|preventDefault={cancelAction}>Cancel</span>
                <button class="button is-success">Save changes</button>
            </slot>
        </footer>
    </form>
    </div>
</div>

<style>
    .modal-card-foot {
        justify-content: flex-end;
    }</style>