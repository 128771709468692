<script>


    import { notify } from "../../../../_components/svelte-notify/src/notification/index";
    import {onMount} from 'svelte';

    import {db} from '../../../../lib/database.svelte';
    import Loader from '../../../../_components/Loader.svelte';
    import RenderTemplate from '../../../../_components/RenderTemplate.svelte';

    import { params, url } from '@roxi/routify';
    import Library from "../../../../lib/models/LibraryModel";
    import Item from "../../../../lib/models/ItemModel";


    export let libraryID;

    let libraryTemplate;
    let data = "";

    let templateArea;
    let cssArea;
    let jsonArea;

    let templateCM;
    let cssCM;
    let jsonCM;

    let library;
    let items;

    let err = "";

    function loadTemplate(id) {
        if (!id) return;

        let libraryModel = new Library($params.systemID, id);
        library = libraryModel.store;

        items = Item.getItemList($params.systemID, id);

        db.get(`template:${$params.systemID}:${id}`).then((result) => {
            libraryTemplate = result;

            if (templateCM) {
                templateCM.setValue(libraryTemplate.template);
            }
            if (cssCM) cssCM.setValue(libraryTemplate.css);
        }).catch((e) => {
            console.log(e);
            libraryTemplate = {
                _id: `template:${$params.systemID}:${id}`,
                template: "",
                css: ""
            }
        });
    }

    function loadItem(evt) {
        if (!evt.target.value) return;

        data = $items[evt.target.value].data;

        jsonCM.setValue(JSON.stringify(data, null, 3));
    }

    function saveTemplate() {
        db.put(libraryTemplate).then((result) => {
            libraryTemplate._rev = result.rev;
            console.log("saved");
            notify({
                message: "Template Saved",
                type: "success",
                timeout: 1000,
            })
        });
    }

    function replaceTemplateArea (area) {
       if (!area) return;

        templateCM = CodeMirror.fromTextArea(area, {
            theme: 'eclipse',
            mode: "htmlmixed",
            lineWrapping: true,
            lineNumbers: true
        });

        templateCM.on("change", (editor, change) => {
            libraryTemplate.template = editor.doc.getValue();
        });
    }

    function replaceCSSArea(area) {
        if (!area) return;

        cssCM = CodeMirror.fromTextArea(area, {
            theme: "eclipse",
            mode: "css",
            lineWrapping: true,
            lineNumbers: true

        });

        cssCM.on("change", (editor, change) => {
            libraryTemplate.css = editor.doc.getValue();
        })
    }

    function replaceJSONArea(area) {
        if (!area || jsonCM) return;

        jsonCM = CodeMirror.fromTextArea(area, {
            theme: "eclipse",
            mode: {name: "javascript", json: true},
            lineWrapping: true,
            lineNumbers: true

        });

        jsonCM.on("change", (editor, change) => {
            try {
                data = JSON.parse(editor.doc.getValue());
            } catch (e) {
                err = e;
            }
        });
    }



    $: loadTemplate(libraryID);

    $: replaceTemplateArea(templateArea);
    $: replaceCSSArea(cssArea);
    $: replaceJSONArea(jsonArea);
</script>

<section class="section">
    <Loader loading={!libraryTemplate}>
        <form on:submit|preventDefault={saveTemplate}>
            <h1 class="title">
                Edit Template - {#if $library}{$library.name}{/if}
                <div class="buttons is-pulled-right">
                    <a href={$url(`../${libraryID}`)} class="button is-link">Close</a>
                    <div class="control">
                        <button class="button is-success">Save</button>
                    </div>
                </div>

            </h1>
            <div class="columns">
                <div class="column">
                    <div class="field">
                        <label class="label" for="css">
                            CSS
                        </label>
                        <div class="control codemirror">
                            <textarea class="textarea" id="css" name="css" bind:this={cssArea}>{libraryTemplate.css}</textarea>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label" for="preview-data">
                            Preview Data (JSON)
                            <div class="select is-pulled-right">
                                <select on:blur={loadItem}>
                                    <option>-- Select an Item to Preview</option>
                                    {#each $items as item, idx }
                                    <option value={idx}>{item.name}</option>
                                    {/each}
                                </select>
                            </div>
                        </label>
                        <div class="control codemirror">
                            <textarea class="textarea" id="preview-data" name="preview" bind:this={jsonArea}>{data}</textarea>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="field">
                        <label class="label" for="template">
                            Template
                        </label>
                        <div class="template-control control codemirror">
                            <textarea id="template" class="textarea" name="template" bind:this={templateArea}>{libraryTemplate.template}</textarea>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <span class="label">Preview: </span>
                    <div class="preview">
                        <RenderTemplate template={libraryTemplate} {data} bind:error_message={err} />
                    </div>
                    {#if err}
                    <div class="notification is-warning">{err}</div>
                    {/if}
                </div>
            </div>
        </form>
    </Loader>
</section>

<style>
    .preview {
        border: 1px solid black;
        width: 100%;
        height: calc(100vh - 200px);
    }

    .control :global(.CodeMirror) {
        height: calc(50vh - 124px);
        border-radius: 6px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        font-family: 'Source Code Pro', monospace;
        font-size: 14px;
        font-weight: 400;
        height: calc(50vh - 124px);
        letter-spacing: normal;
        line-height: 20px;

        tab-size: 4;
    }

    .template-control :global(.CodeMirror) {
        height: calc(100vh - 200px);
    }</style>