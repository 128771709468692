<script>
    import { showContextMenu } from "../ContextMenu.svelte";

    import Icon from "svelte-fa";
    import {faEye, faEyeSlash, faUserAlt, faUserAltSlash, faLock, faLockOpen} from "@fortawesome/free-solid-svg-icons";
    import { getLayerChildren } from "../../lib/helpers";
    import {dispatch} from '../../lib/EventManager.svelte';

    export let map = locationModel.map;
    export let selectedLayer = undefined;

    let dropLayer;
    let indent = 35;

    function getLevel(l) {
        if (!(l || {}).parent) return 0;

        return 1 + getLevel($map.layers[l.parent]);
    }

    function showLayerContextMenu(evt, layer) {
        selectedLayer = layer.id;
        let actions = [];
        if (layer.layer_type == "container") {
            actions.push({
                name: "Add",
                action: () => {
                    dispatch("add", {
                        layer
                    });
                }
            });
        }

        if (layer.layer_type == "token" || layer.layer_type == "container") {
            actions.push({
                name: "Add to Encounter",
                action: () => {
                    if (layer.layer_type == "token") {
                        dispatch("addToEncounter", layer);
                    } else {
                        for (let child of getLayerChildren(layer.id, $map, true)) {
                            if (child.layer_type == "token") {
                                dispatch("addToEncounter", child);
                            }
                        }
                    }
                }
            })
        }

        actions.push({
            name: "Delete",
            action: () => {
                dispatch("deleteLayer", layer);
            }
        });

        showContextMenu(evt.clientX, evt.clientY, actions)
    }

    function isCollapsed(l) {
        if (!l.parent) return false;

        let parent = $map.layers[l.parent];
        if (!parent || parent.collapsed) return true;

        return isCollapsed(parent);
    }

    function isParent(child, parent) {
        if (!child.parent) return false

        return child.parent == parent.id ? true : isParent($map.layers[child.parent], parent);
    }

    function onDragStart(evt) {
        evt.dataTransfer.setData('text', this.dataset.id);
    }

    function onDragOver(evt) {
        evt.dataTransfer.dropEffect = 'move';

        return false;
    }

    function onDragEnter(evt) {
        dropLayer = this.dataset.id;
        console.log(dropLayer);
    }

    function onDrop(evt) {
        let src = $map.layers[evt.dataTransfer.getData('text')]
        let dst = $map.layers[dropLayer];

        if (dst) {
            dropLayer = null;
            if (src == dst) return;

            if (isParent(dst, src)) return alert("Can't add a Layer into itself!");
        }

        dispatch("moveLayer", {
            src: src,
            dst: dst
        })
    }

    function toggle(layer, prop) {
        layer[prop] = !layer[prop];
        console.log(layer);

        saveLayer(layer);
    }

    function saveLayer(layer) {
        dispatch("saveLayer", layer)
    }

</script>

<ul>
    <li >
        <div class="row"
            on:click={() => selectedLayer = undefined}
            class:is-active={!selectedLayer}

            on:dragover|preventDefault={onDragOver}
            on:dragenter|preventDefault={onDragEnter}
            on:drop|preventDefault={onDrop}
        >
            Map
        </div>
    </li>

    {#each $map.ordered_layers as layer (layer.id)}
        <li
            data-id={layer.id}
            draggable="true"

            on:dragstart={onDragStart}
            on:dragover|preventDefault={onDragOver}
            on:dragenter|stopPropagation={onDragEnter}
            on:drop|stopPropagation|preventDefault={onDrop}
        >
            <div class="row"
                on:contextmenu|preventDefault={(evt) => showLayerContextMenu(evt, layer)}
                class:is-active={layer.id == selectedLayer}
                class:is-hidden={isCollapsed(layer)}
                class:dragOver={layer.id == dropLayer}
                on:click={() => selectedLayer = layer.id}
                style="padding-left: {indent * ( 1 + getLevel(layer))}px;"
            >
                {#if layer.layer_type == "container"}
                    <label class="collapse">
                        <input type=checkbox bind:checked={layer.collapsed} value={layer.id}>
                        {#if layer.collapsed}
                            <span>[+]</span>
                        {:else}
                            <span>[-]</span>
                        {/if}
                    </label>
                {:else if layer.layer_type != "fog" && layer.layer_type != "dynamic_lighting"}
                    <label>
                        <input type="checkbox" bind:checked={layer.locked} on:change={() => saveLayer(layer)}>
                        <Icon icon={layer.locked ? faLock : faLockOpen} />
                    </label>
                {/if}
                <label>
                    <input type=checkbox bind:checked={layer.hidden} value={layer.id} on:change={() => saveLayer(layer)}>
                    <Icon icon={layer.hidden ? faEyeSlash : faEye} />
                </label>
                <label>
                    <input type=checkbox bind:checked={layer.playerHidden} value={layer.id} on:change={() => saveLayer(layer)}>
                    <Icon icon={layer.playerHidden ? faUserAltSlash : faUserAlt} />
                </label>
                <span>{layer.name}</span>
            </div>
        </li>
    {/each}
</ul>

<style>
    .row {
        display: flex;
        cursor: pointer;
    }
    .row input {
        display: none;
    }
    .row.is-active {
        background-color: hsl(204, 86%, 53%);
        color: white;
    }
    .row:hover {
        background-color: hsl(171, 100%, 41%);
        color: white;
    }
    label {
        margin-right: 10px;
        cursor: pointer;
    }
    ul {
        width: 100%;
    }
    li {
		list-style-type: none;
        font-size: 18pt;
    }
    .row {
        padding-left: .75em;
        padding-right: .75em;
    }
    label.collapse span {
        font-family: monospace;
    }
    .inactive {
        color: gray;
    }
    .selected {
        background-color: lightGray;
    }
    .hidden {
        display: none;
    }
    .dragOver {
        border: 1px dashed black;
    }</style>
