<script>
    import session from '../lib/Session';

</script>
<!-- routify:options preload="proximity" -->
<div class="outer">
    <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <span class="navbar-item">
                <img src="/images/logo.png" alt="Logo">
            </span>
        </div>

        <div class="navbar-menu">
            <div class="navbar-start">
                <a class='navbar-item' href="/campaign">Campaign Management</a>
                <a class='navbar-item' href="/library">Library Management</a>
            </div>
            <div class="navbar-end">
                {#if $session.loggedIn}
                    <span class="navbar-item has-text-success">
                        Logged In: {$session.user.name}
                    </span>
                    <a class='navbar-item' href="/logout">Logout</a>
                {:else}
                    <a class='navbar-item' href="/login">Login</a>
                {/if}
            </div>
        </div>
    </nav>

    <slot></slot>
</div>

<style>
    .outer {
        display: grid;
        grid-template-rows: min-content auto;
        height: 100vh;
    }

    .outer > :global(span) {
        display: none;
    }</style>