<script>
    import {onMount, setContext} from 'svelte';
    import {key} from '../../lib/map_utils';
    import Layer from './Layer.svelte';
    import FogLayer from './FogLayer2.svelte';
    import DynamicLighting from './DynamicLighting.svelte';

    export let location;
    export let map;
    export let selectedLayer = undefined;
    export let player = false;

    let mapContainer;
    let mapCanvas;
    export let viewport;
    let scale;

    setContext(key, {
        getMap: () => map,
        getViewport: () => viewport
    });

    onMount(() => {

        PIXI.utils.skipHello();

        // Initialize PIXI
        let app = new PIXI.Application({
			view: mapCanvas,
			resizeTo: mapContainer,
			antialias: true,
        });

		viewport = new Viewport.Viewport({
			screenWidth: mapContainer.clientWidth,
			screenHeight: mapContainer.clientHeight,
			worldWidth: location.map_width,
			worldHeight: location.map_height,
            interaction: app.renderer.plugins.interaction,
            disableOnContextMenu: true
        });

        viewport.app = app;

		viewport.drag({
			mouseButtons: "left-middle"
        }).wheel().clamp({
			direction: "all"
		});


        scale = viewport.scaled;


		viewport.sortableChildren = true;

		app.stage.addChild(viewport);

        viewport.on("zoomed-end", (viewport) => scale = viewport.scale._x)

    });

    function handleZoom(scale) {
        if (!viewport) return;

        viewport.scaled = scale;
    }

    window.onresize = (e) => {
		viewport.screenWidth = mapContainer.clientWidth;
		viewport.screenHeight = mapContainer.clientHeight;
    };


    $: handleZoom(scale);
</script>

<div class="map" bind:this={mapContainer}>
    <canvas bind:this={mapCanvas}></canvas>
    <div class="zoom">
        <input
            class="slider is-fullwidth"
            step="0.01"
            min="0.1"
            max="5"
            type="range"
            orient="vertical"
            bind:value={scale}
        >
    </div>
</div>



{#each $map.ordered_layers as layer, idx (layer.id)}
    {#if layer.layer_type == "fog"}
        <FogLayer {layer} {idx} map={$map} {viewport} {selectedLayer} on:saveLayer {player} />
    {:else if layer.layer_type == "dynamic_lighting"}
        <DynamicLighting {layer} {idx} map={$map} {viewport} {selectedLayer} on:saveLayer {player} />
    {:else if layer.layer_type != "container"}
        <Layer {layer} {idx} {viewport} bind:selectedLayer map={$map} on:saveLayer on:layerMove {player} />
    {/if}
{/each}


<style>
    .map {
        height: 100vh;
        position: relative;
    }
    .zoom {
        height: 200px;
        position: absolute;
        top: 50px;
        right: 50px;
    }</style>