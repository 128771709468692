<script>
    import {onDestroy} from "svelte";
    import {params} from "@roxi/routify";
    import Location from '../../../../lib/models/locationModel';
    import Loader from "../../../../_components/Loader.svelte";
    import ChatBox from '../../../../_components/ChatBox.svelte';
    import AddMapLayerFormModal from "../../../../_components/Forms/AddMapLayerFormModal.svelte";
    import MapTreeView from "../../../../_components/Map/MapTreeView.svelte";
    import Map from "../../../../_components/Map/Map.svelte";
    import ToolOptions from "../../../../_components/Map/ToolOptions.svelte";
    import {launchPlayerWindow} from "../../../../_components/PlayerManager.svelte";
    import EncounterManager, {addParticipant} from "../../../../_components/EncounterManager.svelte";
    import { db } from "../../../../lib/database.svelte";
    import { on, off } from "../../../../lib/EventManager.svelte";

    export let locationID;

    let locationModel;
    let location;
    let map;

    let viewport;

    let currentTab = "layers";
    let rightSidebarTab = "chat";
    let show_add_layer = false;

    let selectedLayer;

    function loadLocation(campaign_id, location_id) {
        if (location_id == undefined) {
            locationModel = undefined;
            location = undefined;
            map = undefined
        } else {
            locationModel = new Location(campaign_id, location_id);
            location = locationModel.store;
            map = locationModel.map;
        }

    }

    function saveLayer(layer) {
        Location.saveLayer(layer);
    }

    function showAddLayer() {
        show_add_layer = true;
    }

    function deleteLayer(layer) {
        console.log(layer);
        if (selectedLayer == layer.id) {
            selectedLayer = undefined
        }
        locationModel.deleteLayer(layer);
    }

    function doAddLayer(evt) {
        let data = evt.detail;
        data.parent = selectedLayer;
        locationModel.addLayer(data, viewport);
        closePopup();
    }

    function handleMove(detail) {
        let from = detail.src;
        let to = detail.dst;

        locationModel.moveLayer(from, to);
    }

    async function handleAddEncounter(layer) {
        let item = {};
        if (layer.link) {
            item = await db.get(layer.link)
        }
        addParticipant(layer.id, layer.name, item.data);
    }

    function closePopup() {
        show_add_layer = false;
    }

    function doLaunchPlayerWindow() {
        launchPlayerWindow($params.campaignID, locationID);
    }

    function handleDrop(detail) {
        saveLayer(detail.layer);
    }

    function handleResize(detail) {
        saveLayer(detail.layer);
    }

    $: loadLocation($params.campaignID, locationID);

    on("layerDrop", handleDrop);
    on("layerResize", handleResize);
    on("saveLayer", saveLayer);
    on("saveMapDetails", (detail) => Location.saveMap(detail));
    on("deleteLayer", (detail) => deleteLayer(detail));
    on("moveLayer", handleMove);
    on("addToEncounter", handleAddEncounter);

    onDestroy(() => {
        off("layerDrop", handleDrop);
        off("saveLayer", saveLayer);
        off("saveMapDetails", (detail) => Location.saveMap(detail));
        off("deleteLayer", deleteLayer);
        off("moveLayer", handleMove);
        off("addToEncounter", handleAddEncounter)
    })
</script>

<Loader loading={!$location}>
    <div class="columns">
        <div class="column is-2 sidebar">
            <div class="panel">
                <p class="panel-heading">
                    {$location.location_name}
                </p>
                <p class="panel-tabs">
                    <span class="link" class:is-active={currentTab == "layers"} on:click={() => currentTab = "layers"}>Layers</span>
                    <span class="link"class:is-active={currentTab == "players"} on:click={() => currentTab = "players"}>Players</span>
                </p>
                {#if currentTab == "layers"}
                    <div class="panel-block is-paddingless map-tree">
                    <MapTreeView {map} bind:selectedLayer={selectedLayer} />
                    </div>
                    <div class="panel-block bottom">
                        <button class="button is-link is-outlined is-fullwidth" on:click={showAddLayer}>
                            Add Layer
                        </button>
                    </div>
                    <div class="panel-block">
                        <div style="width: 100%">
                            <ToolOptions {map} {selectedLayer} on:saveLayer={saveLayer} />
                        </div>
                    </div>
                {:else if currentTab == "players"}
                    <div class="panel-block">
                        <button class="button is-link is-outlined is-fullwidth" on:click={doLaunchPlayerWindow}>Launch Player Window</button>
                    </div>
                {/if}
            </div>
        </div>
        <div class="column">
            <Map {map} location={$location} bind:selectedLayer on:saveLayer={saveLayer} bind:viewport />
        </div>

        <div class="column is-2 sidebar">
            <div class="panel">
                <p class="panel-heading">
                    Chat
                </p>
                <p class="panel-tabs">
                    <span class="link" class:is-active={rightSidebarTab == "chat"} on:click={() => rightSidebarTab = "chat"}>Chat</span>
                    <span class="link" class:is-active={rightSidebarTab == "encounter"} on:click={() => rightSidebarTab = "encounter"}>Encounter</span>
                </p>
                <div class="panel-block grow" class:is-hidden={rightSidebarTab != "chat"}>
                    <ChatBox />
                </div>
                <div class="panel-block grow" class:is-hidden={rightSidebarTab != "encounter"}>
                    <EncounterManager />
                </div>

            </div>
        </div>
    </div>
</Loader>

<style>
    .sidebar {
        max-width: 400px;
    }
    .panel {
        height: 100%;
    }
    .columns {
        height: 100vh;
        margin: 0;
        overflow: hidden;
    }
    .column {
        padding: 0;
    }
    .map-tree {
        align-items: start;
        overflow: auto;
    }</style>

{#if show_add_layer}
    <AddMapLayerFormModal on:submit={doAddLayer} on:cancel={closePopup} />
{/if}