<script context="module">
    import { writable } from "svelte/store";
    import {getRandomIntInclusive, rollDice} from "../lib/helpers";

    import Icon from "svelte-fa";
    import {faHandRock, faTrash, faChevronUp, faChevronDown} from "@fortawesome/free-solid-svg-icons"

    let participants = writable([]);

    let hpStat = "hp";
    let hpRoll;

    let initiativeStat = "perception";
    let initiativeRoll;

    export function addParticipant(id, name, data) {
        let hp, maxHp, initiative;

        if (data && hpStat) {
            hp = maxHp = data[hpStat];
        }

        if (data && initiativeStat) {
            let roll = rollDice(`1d20+${data[initiativeStat]}`);
            console.log(`1d20+${data[initiativeStat]}`);
            console.log(roll);
            initiative = roll[0];
        }


        participants.update(p =>  [...p, {
            id,
            name,
            initiative: initiative || 0,
            hp: hp || 0,
            maxHp: maxHp || 0,
            data
        }]);

        sortParticipants();
    }

    let sortFunc = (a,b) => {
        // Players win ties. Otherwise ties are random.
        if (a.initiative != b.initiative)
            return b.initiative - a.initiative
        else {
            return getRandomIntInclusive(-1,1);
        }
    }

    export function removeParticipant(id) {
        participants.update(ps => ps.filter((p) => p.id != id));
    }

    function sortParticipants() {
        participants.update(p => {
            p.sort(sortFunc);
            return p
        });
    }

    function next() {
        participants.update(p => {
            p.push(p.shift());
            return p;
        })
    }
</script>

<script>
    import Field from "./Forms/Field.svelte";
    import {addFormModal} from './ModalFormManager.svelte';
    let showOptions = false;

    function takeDamage(idx) {
        addFormModal({
            fields: [{
                name: "damage",
                label: "Damage Amount",
                require: true,
                type: "number"
            }]
        }).then(data => {
            $participants[idx].hp -= data.damage;
        })
    }
</script>

<div class="container flex-column">
    <div class="encounter-list grow">
        <table class="table is-fullwidth">
            <thead>
                <tr>
                    <th>Init.</th>
                    <th>Name</th>
                    <th width="120px">HP</th>
                    <th>Del.</th>
                    <th>DMG</th>
                </tr>
            </thead>
            <tbody>
                {#each $participants as participant, idx (participant.id)}
                <tr class:is-selected={idx == 0} class:is-danger={participant.hp <= 0}>
                    <td>
                        <input class="is-pulled-right" bind:value={participant.initiative} on:change={sortParticipants}>
                    </td>
                    <td>
                        {participant.name}
                    </td>
                    <td class="hp">
                        <span>
                            <input bind:value={participant.hp}>
                            {#if participant.maxHp} / {participant.maxHp}{/if}
                        </span>
                    </td>
                    <td  class="has-text-centered">
                        <span class="link" on:click={() => removeParticipant(participant.id)}><Icon icon={faTrash} /></span>
                    </td>
                    <td  class="has-text-centered">
                        <span class="link" on:click={() => takeDamage(idx)}><Icon icon={faHandRock} /></span>
                    </td>
                </tr>
                {/each}
            </tbody>
        </table>
    </div>
    <div class="bottom">
        <div class="collapse">
            <label class="label pointer">
                Options
                <span class="is-pulled-right">
                    {#if showOptions}
                        <Icon icon={faChevronUp} />
                    {:else}
                        <Icon icon={faChevronDown} />
                    {/if}
                </span>
                <input bind:checked={showOptions} class="is-hidden" type="checkbox">
            </label>
            {#if showOptions}
                <div>
                    <Field name="hp_stat" bind:value={hpStat} label="HP Stat" />
                    <Field name="init_stat" bind:value={initiativeStat} label="Initiative Stat" />
                </div>
            {/if}
        </div>

        <button class="button is-fullwidth is-primary" on:click={next}>Next Turn</button>
        <!--<button class="button is-fullwidth">Sort Monsters Together</button>-->
    </div>
</div>

<style>
    .container {
        height: 100%;
    }

    input {
        width: 2em;
        text-align: center;
        border: 0;
        font-size: 1rem;
    }

    .collapse {
        padding-bottom: 1em;
    }

    .table tr.is-danger {
        background-color: hsl(348, 100%, 61%);
    }</style>