<script>
    import System from '../../../lib/models/SystemModel';
    import Library from '../../../lib/models/LibraryModel';
    import {showContextMenu} from '../../../_components/ContextMenu.svelte';

    import {addFormModal} from '../../../_components/ModalFormManager.svelte';
    import {isActive, url, goto} from '@roxi/routify'

    export let systemID;


    let systemModel;
    let library_list;

    let system;

    function loadSystem(id) {
        if (!id) {
            return;
        }

        systemModel = new System(id);

        library_list = Library.getLibraries(id);
        system = systemModel.store
    }

    function addSystem() {
        let fields = [
            {
                name: "id",
                label: "ID",
                placeholder: "Unique System ID",
                required: true
            },
            {
                name: "name",
                label: "Name",
                placeholder: "System Name",
                required: true
            }
        ]

        addFormModal({
            fields: fields,
            title: "Add Library"
        }).then((data) => {
            Library.addLibrary(systemID, data);
        }).catch(e => {
            console.log(e);
        })
    }

    function handleRightClick(evt, lib) {
        showContextMenu(evt.clientX, evt.clientY, [
            {name: "Delete", action: () => {
                if ($isActive(`./${lib.id}`)) {
                    $goto('./');
                }

                Library.deleteLibrary(lib);
            }}

        ])
    }

    $: loadSystem(systemID);
</script>

<div class="column is-2 library-column">

    {#if $system}
        <nav class="panel library-panel">
            <p class="panel-heading">
                Libraries <br />
                <span class="is-size-6">{$system.name}</span>
            </p>

            {#if $library_list}
            {#each $library_list as lib}
                <a href="{$url(`./${lib.id}`)}" class:active={$isActive(`./${lib.id}`)} class="panel-block" on:contextmenu|preventDefault={(evt) => handleRightClick(evt, lib)}>
                    <p class="control has-icons-right">
                        <span>{lib.name}</span>
                        <a href="{$url(`./template/${lib.id}`)}" class:active={$isActive(`./template/${lib.id}`)} class="panel-icon" title="Manage Template">
                            🗎
                        </a>
                    </p>
                </a>
            {/each}
            {/if}

            <div class="panel-block library-add-button">
                <button class="button is-link is-outlined is-fullwidth" on:click={addSystem}>
                    Add New Library
                </button>
            </div>
        </nav>
    {:else}
        <div>
        </div>
    {/if}
</div>

<slot></slot>

<style>
    .library-column {
        height: 100%;
    }

    .library-panel {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .library-add-button {
        margin-top: auto;
    }

    p.control {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .panel-icon {
        font-size: 24pt;
        text-align: right;
    }

    .panel-icon.active {
        color: hsl(204, 86%, 53%);
    }</style>