<script>
    import {onMount} from "svelte";

    export let label = "";
    export let value = "";
    export let type = "text";
    export let placeholder = "";
    export let name;
    export let dflt = "";
    export let required = false;

    let fileinput;
    let filevalue;

    export let choices = {};

    let options = [];

    let filename = "";

    function handle_file(filevalue) {
        if (!fileinput)
            return;

        filename = "";

        for (let file of fileinput.files) {
            filename += file.name
        }

        value = fileinput.files;

        return;
    }

    $: handle_file(filevalue);

    onMount(async () => {
        if (dflt && !value) {
            value = dflt;
        }

        if (type == "select") {
            if (choices.static) {
                options = choices.static
            }
        }
    })

    export let errors = [];
</script>

{#if type != "file"}
<div class="field">
    {#if label}
        <label for={name} class="label">
            {#if type == "checkbox"}
                <input id={name} name={name} type="checkbox" bind:checked={value} on:change>
            {/if}
            {label}
        </label>
    {/if}
    <div class="control">
        {#if type == "text"}
            <input class="input {errors[name] ? 'is-danger' : ''}" type="text" {placeholder} bind:value={value} {name} {required}  on:change>
        {:else if type =="number"}
            <input class="input {errors[name] ? 'is-danger' : ''}" type="number" {placeholder} bind:value={value} {name} {required} on:change>
        {:else if type == "password"}
            <input class="input {errors[name] ? 'is-danger' : ''}" type="password" {placeholder} bind:value={value} {name} {required}  on:change>
        {:else if type == "email"}
            <input class="input {errors[name] ? 'is-danger' : ''}" type="email" {placeholder} bind:value={value} {name} {required}  on:change>
        {:else if type=="tel"}
            <input class="input {errors[name] ? 'is-danger' : ''}" type="tel" {placeholder} bind:value={value} {name} {required}  on:change>
        {:else if type=="date"}
            <input class="input {errors[name] ? 'is-danger' : ''}" type="date" {placeholder} bind:value={value} {name} {required}  on:change>
        {:else if type=="select"}
            <div class="select is-fullwidth">
                <select {name} {required} bind:value={value} on:change>
                    {#each options as option}
                        <option value={option.value}>{option.label || option.value}</option>
                    {/each}
                </select>
            </div>
        {:else if type=="textarea"}
            <textarea class="textarea" {placeholder} bind:value={value} {name} {required}></textarea>
        {/if}
    </div>
    {#if errors[name]}
        <p class="help is-danger">{errors[name]}</p>
    {/if}
</div>
{:else}
{#if label}
    <label for={name} class="label">{label}</label>
{/if}
<div class="file has-name">
  <label class="file-label">
    <input class="file-input" type="file" name={name} bind:value={filevalue} bind:this={fileinput} {required}>
    <span class="file-cta">
      <span class="file-icon">
        <i class="fas fa-upload"></i>
      </span>
      <span class="file-label">
        Choose a file…
      </span>
    </span>
    <span class="file-name">
        {filename}
    </span>
  </label>
</div>
{/if}

<style>
    .file-cta, .file-name, .file-cta:hover {
        color: #4a4a4a !important;
        background-color: white;
    }</style>