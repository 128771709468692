<div class="library">
    <slot></slot>
</div>

<style>
    .library {
        display: grid;
        grid-template-columns: 300px auto;
        width: 100%;
        height: 100%;
    }</style>