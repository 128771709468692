<script>
    import { url } from "@roxi/routify";
    import { get } from "svelte/store";

    import Campaign from "../../../lib/models/campaignModel";
    import Location from "../../../lib/models/locationModel";
    import ChatBox from "../../../_components/ChatBox.svelte";
    import Loader from "../../../_components/Loader.svelte";
    import {addFormModal} from "../../../_components/ModalFormManager.svelte";

    import {showContextMenu} from "../../../_components/ContextMenu.svelte";

    export let campaignID;
    export let campaign;

    let locations;

    let currentTab = "locations";

    let fields = [
        {
            name: "location_name",
            label: "Location Name",
            required: true
        },
        {
            name: "map_width",
            label: "Map Width",
            required: true,
            type: "number",
            dflt: 1920
        },
        {
            name: "map_height",
            label: "Map Height",
            required: true,
            type: "number",
            dflt: 1080
        },
    ];

    function getCampaign(id) {
        if (!id) return;

        campaign = new Campaign(id).store;
        locations = Location.getLocationList(id);
    }

    function addLocation() {
        addFormModal({
            fields,
            title: "Add Location"
        }).then((formData) => {
            Location.addLocation(campaignID, formData)
        });
    }

    function handleContextMenu(evt, location) {
        console.log("HERE");
        showContextMenu(evt.clientX, evt.clientY, [
            {
                name: "Edit Location",
                action: () => {
                    addFormModal({
                        fields,
                        title: "Edit Location",
                        data: location
                    }).then((formData) => {
                        Location.updateLocation(formData)
                    })
                }
            },
            {
                name: "Delete Location",
                action: () => Location.deleteLocation(location)
            }
        ])
    }

    function deleteLocation(location) {
        Campaign.deleteLocation(location)
    }

    $: getCampaign(campaignID);
</script>

<Loader loading={!$campaign}>
    <div class="columns">
        <div class="column is-2 sidebar">
            <div class="panel">
                <p class="panel-heading">
                    {$campaign.name}
                </p>
                <p class="panel-tabs">
                    <span class="link is-active">Locations</span>
                    <span class="link">Public</span>
                    <span class="link">Private</span>
                    <span class="link">Sources</span>
                    <span class="link">Forks</span>
                  </p>
                  {#if currentTab == "locations" && $locations}
                      {#each $locations as location (location.id)}
                          <a href={$url(`./location/${location.id}`)} class="panel-block is-active" on:contextmenu|preventDefault={(evt) => handleContextMenu(evt, location)}>
                              {location.location_name}
                          </a>
                      {/each}
                      <div class="panel-block bottom">
                          <button class="button is-link is-outlined is-fullwidth" on:click={addLocation}>
                              Add Location
                          </button>
                      </div>
                  {/if}
            </div>
        </div>
        <div class="column">
            <slot></slot>
        </div>

        <div class="column is-2">
            <div class="panel">
                <p class="panel-heading">
                    Chat
                </p>
                <p class="panel-tabs">
                    <span class="link is-active">Chat</span>
                    <span class="link">Public</span>
                    <span class="link">Private</span>
                    <span class="link">Sources</span>
                    <span class="link">Forks</span>
                </p>
                <div class="panel-block">
                    <ChatBox></ChatBox>
                </div>
            </div>
        </div>
    </div>
</Loader>

<style>
    .panel {
        height: 100%;
    }

    .columns {
        height: 100vh;
    }</style>