<script>
    import {showContextMenu} from '../../../../_components/ContextMenu.svelte';
    import Loader from '../../../../_components/Loader.svelte';
    import Library from '../../../../lib/models/LibraryModel';
    import Item from '../../../../lib/models/ItemModel';
    import {isActive, url, goto} from '@roxi/routify'
    import { params } from '@roxi/routify'
    import {addFormModal} from '../../../../_components/ModalFormManager.svelte';
    import { db } from '../../../../lib/database.svelte';

    export let libraryID;

    let libraryModel;
    let library;
    let items;

    let searchTerm;
    let filteredItems = [];

    let importing = false;

    function loadLibrary(id) {
        libraryModel = new Library($params.systemID, id);

        items = Item.getItemList($params.systemID, id);
        library = libraryModel.store;
    }

    function handleRightClick(evt, item) {
        showContextMenu(evt.clientX, evt.clientY, [
            {name: "Delete", action: () => {
                if ($isActive(`./${item.id}`)) {
                    $goto('./');
                }
                Item.deleteItem(item);
            }}

        ])
    }

    function importEntries() {
        let fields = [
            {
                name: "import_file",
                label: "Import File",
                type: 'file',
                required: true
            }
        ]

        addFormModal({
            fields: fields,
            title: "Import File"
        }).then(async (form_data) => {
            importing = true;
            console.log("START");
            let promises = [];

            for (var i = 0; i < form_data.import_file.length; i++) {
                let json = await form_data.import_file.item(i).text();
                let items = JSON.parse(json);
                for (let item of items) {
                    promises.push(Item.addItem($params.systemID, libraryID, {
                        id: item.id,
                        name: item.name,
                        data: item
                    }).catch((err) => {
                        if (err.status == 409) {
                            db.get(err.id).then((res) => {
                                res.data = item;
                                res.name = item.name;
                                return db.put(res);
                            })
                        }
                    }));
                };
            }

            await Promise.all(promises);
            importing = false;
            console.log("END");
        });
    }

    function addEntry() {
        let fields = [
            {
                name: "id",
                label: "ID",
                placeholder: "Unique System ID",
                required: true
            },
            {
                name: "name",
                label: "Name",
                placeholder: "System Name",
                required: true
            },
            {
                name: "json",
                label: "JSON",
                placeholder: "Entry Data",
                type: "textarea"
            }
        ]


        let validate = (data) => {
            if (data.json) {

                try {
                    data.data = JSON.parse(data.json);
                    data.json = undefined;
                } catch (error) {
                    return {
                        success: false,
                        message: `Invalid JSON Data: ${error}`
                    }
                }
            }

            return {
                success: true
            }
        }

        addFormModal({
            fields: fields,
            title: "Add Library",
            validate
        }).then((item) => {
            Item.addItem($params.systemID, libraryID, item);
        }).catch(e => {
            console.log(e);
        })
    }

    function filterItems(items, search) {
        if (!items) return;

        if (!search) {
            filteredItems = items;
            return
        }

        filteredItems = items.filter(i => i.name.toLowerCase().includes(search.toLowerCase()));
    }

    $: filterItems($items, searchTerm)
    $: loadLibrary(libraryID);
</script>

<div class="column is-2 entry-column">

    <Loader loading={$library == undefined}>
        <nav class="panel entry-panel">
            <p class="panel-heading">
                Entries <br />
                <span class="is-size-6">{$library.name}</span>
            </p>

            <div class="panel-block">
                <p class="control has-icons-left">
                    <input class="input" type="text" placeholder="Search" bind:value={searchTerm}>
                    <span class="icon is-left">
                        &#128269;
                    </span>
                </p>
            </div>

            <div class="items">
                {#each filteredItems as item}
                    <a href="{$url(`./${item.id}`)}" class:active={$isActive(`./${item.id}`)} class="panel-block" on:contextmenu|preventDefault={(evt) => handleRightClick(evt, item)}>
                        {item.name} ({item.id})
                    </a>
                {/each}
            </div>


            <div class="panel-buttons">
                <div class="panel-block">
                    <button class="button is-info is-outlined is-fullwidth" on:click={importEntries}>
                        Import Entries
                    </button>
                </div>
                <div class="panel-block library-add-button">
                    <button class="button is-link is-outlined is-fullwidth" on:click={addEntry}>
                        Add New Entry
                    </button>
                </div>
            </div>
        </nav>
    </Loader>

</div>
{#if importing}
    <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="box">
                <Loader message="Importing"></Loader>
            </div>
        </div>
    </div>
{/if}

<slot></slot>

<style>
   .entry-column {
       height: 100%;
   }

    .entry-panel {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .panel-buttons {
        margin-top: auto;
        width: 100%;
    }

    .items {
        overflow: auto;
    }</style>