<script>
    import { params } from '@roxi/routify'
    import Item from '../../../../../lib/models/ItemModel';
    import {notify} from "../../../../../_components/svelte-notify/src/notification/index";
    import RenderTemplate from '../../../../../_components/RenderTemplate.svelte';
    import {db} from '../../../../../lib/database.svelte';

    export let itemID;

    let itemModel;
    let item;

    let itemTemplate;
    let err;

    let jsonArea;

    function loadItem(id) {
        if (!id) return;
        itemModel = new Item($params.systemID, $params.libraryID, id);
        item = itemModel.store;

        db.get(`template:${$params.systemID}:${$params.libraryID}`).then((tpl) => {
            itemTemplate = tpl;
        }).catch((err) => {
            itemTemplate = undefined;
        });
    }

    function replaceArea(area) {
        if (!area) return;

        CodeMirror.fromTextArea(area, {
            theme: "eclipse",
            mode: {name: "javascript", json: true},
            lineWrapping: true,
        }).on("change", (editor, change) => {
            try {
                $item.data = JSON.parse(editor.doc.getValue());
            } catch (e) {
                err = e;
            }
        });
    }

    function save() {
        itemModel.save().then( () => {
            notify({
                message: "Entry Saved",
                type: "success",
                timeout: 1500,
            })
        });
    }

    $: loadItem(itemID);

    $: replaceArea(jsonArea);
</script>

<div class="column item-column">
    {#if $item}
    <div class="columns">
        <div class="column item-details">
            <h2 class="title is-2">{$item.name}</h2>
            <p>
                {itemModel.item_id}
            </p>
            <div class="field">
                <div class="control codemirror">
                    <textarea bind:this={jsonArea}>{JSON.stringify($item.data, null, 4)}</textarea>
                </div>
            </div>
            <div class="field">
                <div class="control">
                    <button class="button is-success is-pulled-right" on:click={save}>Save</button>
                </div>
            </div>

        </div>
        {#if itemTemplate}
        <div class="column">
            <RenderTemplate data={$item.data} template={itemTemplate} bind:error_message={err}></RenderTemplate>
            {err}
        </div>
        {/if}
    </div>

    {/if}
</div>

<style>
    .item-column {
        height: 100%;
    }

    .codemirror {
        border: 1px solid black;
        max-width: 100%;
    }

    .item-details {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .codemirror :global(.CodeMirror) {
        height: calc(100vh - 280px);
    }</style>