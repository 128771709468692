<script context="module">
    import {writable} from "svelte/store";
    let top, left, actions;

    let contextMenuVisible = writable(false);

    export function showContextMenu(x, y, act) {
        top = y - 5;
        left = x - 5;
        actions = act;

        contextMenuVisible.set(true);
    }

    function hideMenu() {
        contextMenuVisible.set(false);
    }

    function handleAction(e, act) {
        if (e.button != 0) return
        act.action();
        hideMenu();
    }
</script>

{#if $contextMenuVisible}
    <div class="background" on:mouseup={hideMenu} on:mouseover|self={hideMenu}>

        <ul class="contextMenu box" style="top: {top}px; left:{left}px;">
            {#each actions as action}
                <li on:mouseup|stopPropagation={(e) => handleAction(e, action)}>{action.name}</li>
            {/each}
        </ul>
    </div>
{/if}

<style>
    .background {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 10000;
    }

    .contextMenu {
        position: absolute;
        margin: 0;
        padding: 0;
        background-color: white;
        --border: 1px solid black;
    }

    .contextMenu li:first-of-type {
        padding-top: 10px;
    }

    .contextMenu li:last-of-type {
        padding-bottom: 10px;
        border-bottom: none;
    }

    .contextMenu li {
        cursor: pointer;
        padding: 5px 15px;
        min-width: 150px;
        border-bottom: 1px solid gray;
    }

    .contextMenu li:hover {
        background-color: lightGray;
    }</style>