<script>
    import Field from "../Forms/Field.svelte";

    import { dispatch } from "../../lib/EventManager.svelte";

    export let map;
    export let selectedLayer = undefined;

    let showTools = true;

    function doSaveLayer() {
        dispatch("saveLayer", $map.layers[selectedLayer])
    }
</script>

<label>
    Tool Options
    <input type="checkbox" bind:checked={showTools}>
</label>
<div class="tool" class:hidden={!showTools}>
{#if selectedLayer && $map.layers[selectedLayer] }
    <Field type="text" name="layer_name" label="Layer Name" bind:value={$map.layers[selectedLayer].name} />
    {#if $map.layers[selectedLayer].layer_type == "fog" || $map.layers[selectedLayer].layer_type == "dynamic_lighting"}
    {#if $map.layers[selectedLayer].layer_type == "fog" }
        <Field type="checkbox" name="hidden" label="Hide Areas" bind:value={$map.layers[selectedLayer].invert} />
    {:else}
        <div class="label">Tool</div>
        <Field
            type="checkbox"
            label="Add Walls"
            name="add_walls"
            value={$map.layers[selectedLayer].tool == "add_walls"}
            on:change={
            (evt) => {
                if (evt.target.checked) {
                    $map.layers[selectedLayer].tool = "add_walls";
                } else {
                    $map.layers[selectedLayer].tool = ""
                }
            }} />
            <Field
                type="checkbox"
                label="Add Doors"
                name="add_doors"
                value={$map.layers[selectedLayer].tool == "add_doors"}
                on:change={
                (evt) => {
                    if (evt.target.checked) {
                        $map.layers[selectedLayer].tool = "add_doors";
                    } else {
                        $map.layers[selectedLayer].tool = ""
                    }
            }} />
        <Field type="checkbox" name="remove_walls" label="Remove Walls" value={$map.layers[selectedLayer].tool == "delete_walls"} on:change={(evt) => $map.layers[selectedLayer].tool = "delete_walls"} />
    {/if}


    <div>
        <label>
            GM Opacity: <input type="range" name="layer_alpha" min=0 max=1 step=0.1 bind:value={$map.layers[selectedLayer].alpha}>
        </label>
    </div>
    {:else if $map.layers[selectedLayer].layer_type == "token" || $map.layers[selectedLayer].layer_type == "image"}
        <Field type="text" name="layer_url" bind:value={$map.layers[selectedLayer].url} label="URL" on:change={doSaveLayer} />
        <Field type="number" name="layer_x" bind:value={$map.layers[selectedLayer].x} label="X" on:change={doSaveLayer} />
        <Field type="number" name="layer_y" bind:value={$map.layers[selectedLayer].y} label="Y" on:change={doSaveLayer} />
        <Field type="number" name="layer_height" bind:value={$map.layers[selectedLayer].height} label="Height" on:change={doSaveLayer} />
        <Field type="number" name="layer_width" bind:value={$map.layers[selectedLayer].width} label="Width" on:change={doSaveLayer} />
        {#if $map.layers[selectedLayer].layer_type == "token"}
            <Field type="text" name="layer_link" bind:value={$map.layers[selectedLayer].link} label="Link" on:change={doSaveLayer}/>
            <Field type="checkbox" name="dynamic_vis" label="Show Dynamic Visibility" bind:value={$map.layers[selectedLayer].show_dynamic_lighting} on:change={doSaveLayer}/>
            <Field type="checkbox" name="resize_handles" label="Hide Resize Handles" bind:value={$map.layers[selectedLayer].hide_resize_handles} on:change={doSaveLayer}/>
        {:else if $map.layers[selectedLayer].layer_type == "image"}
            <Field type="checkbox" name="show_grid" label="Show Grid" bind:value={$map.layers[selectedLayer].displayGrid}  on:change={doSaveLayer}/>
        {/if}
    {/if}
{:else}
    <Field type="number" name="grid_size" bind:value={$map.details.gridSize} label="Grid Size" on:change={() => dispatch("saveMapDetails", $map.details)} />
    <Field type="number" name="offset_x" bind:value={$map.details.offsetX} label="Offset X"  on:change={() => dispatch("saveMapDetails", $map.details)} />
    <Field type="number" name="offset_y" bind:value={$map.details.offsetY} label="Offset Y"  on:change={() => dispatch("saveMapDetails", $map.details)} />
    {/if}
</div>

<style>
    .tool {
        flex-grow: 1;
        padding: 10px;
    }

    .hidden {
        display: none;
    }

    label {
        font-weight: bold;
        cursor: pointer;
    }

    label input {
        display: none;
    }</style>